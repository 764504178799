<template>
  <div class="panel col-span-3 rounded px-5 py-4">
    <div class="w-full grid grid-cols-2">
      <div class="font-extrabold">VTAC</div>
      <div class="font-extrabold text-red-600 text-right text-sm">Offline</div>
    </div>
    <div class="w-full grid grid-cols-2 pt-8 pb-3">
      <div><img src="@/assets/img/zoneline_panel_icon.svg" class="float-left" /></div>
      <div><img src="@/assets/img/radiobutton_default.svg" class="float-right" /></div>
    </div>
    <hr class="mt-5 mb-3" />
    <div v-show="!showToggle" class="mb-3">
      <div class="w-full grid grid-cols-2 pb-4 border-bottom">
        <div class="text-blak font-extrabold text-xs align-middle m-auto mx-0">MODE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/dropdown_mode_mixed.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-blak font-extrabold text-xs align-middle m-auto mx-0">TEMP</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/plusminus_75.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-blak font-extrabold text-xs align-middle m-auto mx-0">FAN SPEED</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/dropdown_mode_normal.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 py-4 border-bottom">
        <div class="text-blak font-extrabold text-xs align-middle m-auto mx-0">SWING</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
    </div>
    <div class="w-full grid grid-cols-2 pb-2">
      <div class="font-extrabold text-grey text-sm align-middle m-auto mx-0">AUX SETTINGS</div>
      <div>
        <img
          v-show="showToggle"
          src="@/assets/img/arrowToggle.svg"
          class="arrow relative float-right"
          @click="toggle"
        />
        <img
          v-show="!showToggle"
          src="@/assets/img/arrowToggle_closed.svg"
          class="arrow relative float-right"
          @click="toggle"
        />
      </div>
    </div>
    <div v-show="showToggle">
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">OPERATING</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/dropdown_mode.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">
          MAKEUPPAIR SPEED
        </div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/plusminus.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MINIMUM SAFE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">MAXIMUM SAFE</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">CONTINUOUS</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">EXTERNAL</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
      <div class="w-full grid grid-cols-2 pb-2">
        <div class="text-pgrey font-extrabold text-xs align-middle m-auto mx-0">DUCTED</div>
        <div class="font-extrabold text-right text-sm">
          <img src="@/assets/img/radiobutton_default.svg" class="float-right" />
        </div>
      </div>
    </div>
    <div class="w-full py-4 px-10 set text-center align-middle m-auto block">
      <a class="w-full rounded text-white m-auto text-center bg-black block"
        ><span class="block px-10 pt-3 font-extrabold">Set</span></a
      >
    </div>
    <div class="w-full grid grid-cols-2 pb-2">
      <router-link to="/assign-task" class="text-black text-xs align-middle m-auto mx-0 font-bold"
        >Assign Task ></router-link
      >
      <div class="text-black text-xs align-middle m-auto mx-0 text-right font-bold">
        Remove >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showToggle: false,
      };
    },
    methods: {
      toggle: function() {
        this.showToggle = !this.showToggle;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .panel {
    line-height: 1em;
  }
  .arrow {
    cursor: pointer;
  }
  .set {
    a {
      border-radius: 14px;
      height: 41px;
    }
  }
  .border-bottom {
    border-bottom: 1px solid #d0d0d0;
  }
</style>
